<template>
  <base-section id="about-the-tools">

    <base-section-heading
        color="grey lighten-2"
        :title="$t('about_headline')"
    />

    <div class="justify-center">
      <base-image :src="require(`@/assets/evaluate_masked.png`)" contain max-width="33%" class="mx-auto mb-8"/>
    </div>

    <v-container>
      <v-row>
        <v-col
            v-for="card in cards[$i18n.locale]"
            :key="card.title"
            cols="12"
            md="4"
        >
          <base-info-card v-bind="card"/>
        </v-col>
      </v-row>
    </v-container>

  </base-section>

</template>

<script>
import BaseImage from '@/components/base/Img';

export default {
  name: 'SectionAboutTheTools',
  components: {BaseImage},
  data: () => ({
    cards: {
      en: [
        {
          title: 'Gain insights',
          subtitle: 'Data collection, screening and questionnaires',
          text: 'Be aware of, and anticipate, the sustainability risks facing your business through clear and easy access to information about your supplier base.',
          callout: '01',
        },
        {
          title: 'Evaluate',
          subtitle: 'Assessments and ratings',
          text: 'Assess and evaluate your suppliers in relation to established frameworks, indicated risks and your Supplier Code of Conduct.',
          callout: '02',
        },
        {
          title: 'Take action',
          subtitle: 'Corrective actions and supplier dialog',
          text: 'Follow-up, require corrective action in dialog with your suppliers and make informed decisions to ensure compliance with your requirements.',
          callout: '03',
        },
      ],
      sv: [
        {
          title: 'Få insikter',
          subtitle: 'Datainsamling, kontroller and formulär',
          text: 'Var medveten om och förutse hållbarhetsriskerna för ditt företag genom tydlig och enkel tillgång till information om din leverantörsbas.',
          callout: '01',
        },
        {
          title: 'Utvärdera',
          subtitle: 'Analys av svar och bedömningar',
          text: 'Utvärdera dina leverantörer i förhållande till internationellt erkända ramverk, funna risker och din uppförandekod för leverantörer (Supplier Code of Conduct).',
          callout: '02',
        },
        {
          title: 'Agera',
          subtitle: 'Leverantörsdialog och åtgärdskrav',
          text: 'Följ upp, kräv åtgärder i dialog med dina leverantörer och fatta välgrundade beslut för att säkerställa att hållbarhetskraven uppfylls.',
          callout: '03',
        },
      ],
    },
  }),
}
</script>
